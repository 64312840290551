.share-button {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.share-button button {
  display: flex;
  margin: 2px;
}

.share-button div {
  margin: 2px;
  cursor: pointer;
}

.share-button div:hover {
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}
